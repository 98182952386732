import React from "react"
import { withTranslation } from "react-i18next"
import "./_contact.scss"
import { Formik, Field, Form } from "formik"
import * as Yup from "yup"
import axios from "axios"
const Contact = props => {
  const { t } = props
  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
  const CustomerSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid Email")
      .required("Email is required"),
    phone: Yup.string()
      .matches(phoneRegExp, "Phone number is not valid")
      .min(10, "Too short")
      .max(12, "Too long")
      .required("Phone number is required"),
    firstName: Yup.string()
      .max(50, "Too long")
      .required("First Name is required"),
    lastName: Yup.string()
      .max(50, "Too long")
      .required("Last Name is required"),
    company: Yup.string()
      .max(50, "Too long")
      .required("Company is required"),
    description: Yup.string().required("Description is required"),
    field: Yup.string().required("Please choose your interest")
  })
  const handleSubmit = values => {
    axios
      .post("https://acexis-sver.herokuapp.com/acexis/customer", values)
      .then(res => alert("Thanks for your information!"))
      .catch(err => alert(err))
  }
  return (
    <Formik
      initialValues={{
        email: "",
        phone: "",
        firstName: "",
        lastName: "",
        company: "",
        field: "",
        description: ""
      }}
      validationSchema={CustomerSchema}
      onSubmit={(values, props) => {
        handleSubmit(values)
        props.resetForm(props.initialValues)
      }}
    >
      {({ errors, touched, values, handleChange, handleBlur }) => (
        <section
          id="contact-acexis"
          className="form-wrap form-wrap-contact-section"
          ref={props.ref}
        >
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-6 col-sm-6 contact-about">
                <h2>{t("src.pages.solutionsPage.contact.tellUs")}</h2>
                <div className="contact-about-stats">
                  <div className="stat-item">
                    <div className="stat-num">2</div>
                    <span className="stat-des">
                      {t("src.pages.solutionsPage.contact.years")}
                    </span>
                  </div>
                  <div className="stat-item">
                    <div className="stat-num">300</div>
                    <span className="stat-des">
                      {t("src.pages.solutionsPage.contact.clients")}
                    </span>
                  </div>
                  <div className="stat-item">
                    <div className="stat-num">3</div>
                    <span className="stat-des">
                      {t("src.pages.solutionsPage.contact.offices")}
                    </span>
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-6 col-sm-6 form-wrap-contact">
                <Form>
                  <div className="form-group">
                    <select
                      className="form-select"
                      name="field"
                      value={values.field}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option value="" style={{ color: "rgb(132,132,132)" }}>
                        {t("src.pages.solutionsPage.contact.form.interestedIn")}
                      </option>
                      <optgroup
                        label={t(
                          "src.pages.solutionsPage.contact.form.services.services"
                        )}
                      >
                        <option value="Mobile App Development">
                          {t(
                            "src.pages.solutionsPage.contact.form.services.mobileApp"
                          )}
                        </option>
                        <option value="Enterprise Software Development">
                          {t(
                            "src.pages.solutionsPage.contact.form.services.software"
                          )}
                        </option>
                        <option value="Web Development">
                          {t(
                            "src.pages.solutionsPage.contact.form.services.webApp"
                          )}
                        </option>
                        <option value="Dedicated Team">
                          {t(
                            "src.pages.solutionsPage.contact.form.services.dedicated"
                          )}
                        </option>
                        <option value="IT Consulting">
                          {t(
                            "src.pages.solutionsPage.contact.form.services.consulting"
                          )}
                        </option>
                        <option value="Other">
                          {t(
                            "src.pages.solutionsPage.contact.form.services.other"
                          )}
                        </option>
                      </optgroup>
                      <optgroup
                        label={t(
                          "src.pages.solutionsPage.contact.form.technologies.technologies"
                        )}
                      >
                        <option value="Internet of Things">
                          {t(
                            "src.pages.solutionsPage.contact.form.technologies.internet"
                          )}
                        </option>
                        <option value="Blockchain">
                          {t(
                            "src.pages.solutionsPage.contact.form.technologies.blockchain"
                          )}
                        </option>
                      </optgroup>
                    </select>
                    {errors.field && touched.field ? (
                      <div className="elm2 Invalid"> {errors.field}</div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="form-group">
                    <Field
                      type="text"
                      name="email"
                      placeholder={t(
                        "src.pages.solutionsPage.contact.form.email"
                      )}
                    />
                    {errors.email && touched.email ? (
                      <div className="elm2 Invalid"> {errors.email}</div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="form-group">
                    <Field
                      type="text"
                      name="phone"
                      placeholder={t(
                        "src.pages.solutionsPage.contact.form.phone"
                      )}
                    />
                    {errors.phone && touched.phone ? (
                      <div className="elm2 Invalid"> {errors.phone}</div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="form-group">
                    <Field
                      type="text"
                      placeholder={t(
                        "src.pages.solutionsPage.contact.form.company"
                      )}
                      name="company"
                    />
                    {errors.company && touched.company ? (
                      <div className="elm2 Invalid"> {errors.company}</div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="form-box">
                    <div className="form-group">
                      <Field
                        type="text"
                        placeholder={t(
                          "src.pages.solutionsPage.contact.form.firstName"
                        )}
                        name="firstName"
                      />
                      {errors.firstName && touched.firstName ? (
                        <div className="elm2 Invalid"> {errors.firstName}</div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="form-group">
                      <Field
                        type="text"
                        placeholder={t(
                          "src.pages.solutionsPage.contact.form.lastName"
                        )}
                        name="lastName"
                      />
                      {errors.lastName && touched.lastName ? (
                        <div className="elm2 Invalid"> {errors.lastName}</div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="form-group form-group-description">
                    <textarea
                      className="form-textarea"
                      name="description"
                      maxLength="65000"
                      value={values.description}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder={t(
                        "src.pages.solutionsPage.contact.form.projectDescription"
                      )}
                    ></textarea>

                    {errors.description && touched.description ? (
                      <div className="elm2 Invalid"> {errors.description}</div>
                    ) : (
                      ""
                    )}
                  </div>
                  <button
                    className="form-submit-contact"
                    type="submit"
                    value="Submit"
                  >
                    {t("src.pages.solutionsPage.contact.form.talkToOurTeam")}
                  </button>
                </Form>
              </div>
            </div>
          </div>
        </section>
      )}
    </Formik>
  )
}

export default withTranslation()(Contact)
