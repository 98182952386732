import React from "react"
import Layout from "../components/layout/layout.higherComponent"
import Head from "../components/head"
import IndustriesPage from "../components/industries/industriesPage"

const Industries = () => {
  return (
    <div>
      <Layout>
        <Head title="Industries" />
        <IndustriesPage />
      </Layout>
    </div>
  )
}

export default Industries
