import React, { Component } from "react"
import Contact from "./.././homepageInnos/contact/contact.component"
import TalkToOurTeam from "../homepageInnos/about/TalkToOurTeam"

import IndustriesPageTitle from "./industriesHomePage/insdustriesPageTitle/insdustriesPageTitle"
import ClientsServices from "../servicesPage/servicesHomePage/servicesClients/clientsServices"
import IndustriesPlatform from "./industriesHomePage/industriesPlatform/industriesPlatform"
import SoftwareIndustries from "./industriesHomePage/softwareIndustries/softwareIndustries"
import TheLastTechIndustries from "./industriesHomePage/theLastTechIndustries/theLastTechIndustries"
import AppManagermentIndustries from "./industriesHomePage/appManagerment/appManagerment"

class IndustriesPage extends Component {
  render() {
    return (
      <>
        <IndustriesPageTitle />
        <IndustriesPlatform />
        <SoftwareIndustries />
        <TheLastTechIndustries />
        <AppManagermentIndustries />
        <ClientsServices />
        <TalkToOurTeam />
        <div style={{ paddingTop: "50px" }}>
          <Contact />
        </div>
      </>
    )
  }
}

export default IndustriesPage
